<template>
  <div class="wrapper">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex flex-column">
        <template v-if="codDeposits.length && !isEditingDeposit && !isAddingDeposit">
          <span class="text-caption mr-4">Suma depozytów: {{ depositsSum }}</span>
          <span class="text-caption">Wymagana suma: {{ totalGrossValue }}</span>
        </template>
      </div>
      <template>
        <v-btn
          v-if="!codDeposits.length && !isEditingDeposit && !isAddingDeposit"
          color="primary"
          class="base-hover"
          @click="isAddingDeposit = true"
        >
          Dodaj depozyt
        </v-btn>
        <v-btn
          v-if="isEditingDeposit || isAddingDeposit"
          color="primary"
          outlined
          medium
          icon
          :ripple="false"
          :disabled="isProcessing"
          @click="clearForms"
        >
          <v-icon color="primary">
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </template>
    </div>
    <v-data-table
      v-if="!isEditingDeposit && !isAddingDeposit"
      :items="codDeposits"
      :headers="headers"
      :items-per-page="-1"
      hide-default-footer
      fixed-header
      class="mt-4"
    >
      <template #[`item.depositedAmount`]="{ item }">
        {{ formatValue(item.depositedAmount, 'price') }}
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn
          color="primary"
          class="mx-2"
          small
          icon
          @click="setEditingDeposit(item)"
        >
          <v-icon color="primary">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-form
      v-else-if="isEditingDeposit"
      id="editDepositForm"
      ref="editDepositForm"
      class="custom-form"
      v-model="isFormValid"
      @submit.prevent="updateDeposit"
    >
      <div class="d-flex pt-6">
        <v-text-field
          outlined
          v-model.number="editingDeposit.depositedAmount"
          :disabled="isProcessing"
          :rules="[
            rules.required,
            rules.money,
            rules.lowerOrEqualThan(order.payment.totalGrossValue / 100),
            rules.greaterThan(-1)
          ]"
          placeholder="Edytuj zapłaconą kwotę"
          label="Rozliczono [zł]"
          type="number"
          @wheel="$event.target.blur()"
        />
        <v-btn
          color="primary"
          class="base-hover ml-4"
          :loading="isProcessing"
          :disabled="!isFormValid"
          type="submit"
        >
          Edytuj
        </v-btn>
      </div>
    </v-form>
    <v-form
      v-else-if="isAddingDeposit"
      id="addDepositForm"
      ref="addDepositForm"
      class="custom-form"
      v-model="isFormValid"
      @submit.prevent="addDeposit"
    >
      <div class="d-flex pt-6">
        <v-text-field
          outlined
          v-model.number="newDepositAmount"
          :disabled="isProcessing"
          :rules="[
            rules.required,
            rules.money,
            rules.lowerOrEqualThan(order.payment.totalGrossValue / 100),
            rules.greaterThan(-1)
          ]"
          placeholder="Dodaj zapłaconą kwotę"
          label="Rozliczono [zł]"
          type="number"
          @wheel="$event.target.blur()"
        />
        <v-btn
          color="primary"
          class="base-hover ml-4"
          :loading="isProcessing"
          :disabled="!isFormValid"
          type="submit"
        >
          Dodaj
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatValue } from '../../utils/typesEnum'
import rules from '../../utils/validators'
import api from '../../api/v1'

const headers = [
  { text: 'Id', value: 'id' },
  { text: 'Zapłacono', value: 'depositedAmount', sortable: false },
  { text: 'Data zapłaty', value: 'depositedAt' },
  { text: 'Odbiorca', value: 'chargedByName' },
  { text: 'Akcja', value: 'action', sortable: false }
]

export default {
  data: () => ({
    headers,
    isProcessing: false,
    isAddingDeposit: false,
    newDepositAmount: null,
    editingDeposit: null,
    isFormValid: true,
    rules
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog.data,
      field: state => state.layout.dialog.data.field,
      order: state => state.order.entity,
      userId: state => state.auth.user?.id
    }),
    data() {
      return this.dialog.item
    },
    codDeposits() {
      return this.data.codDeposits.map(deposit => ({
        ...deposit,
        depositedAt: this.formatValue(deposit.depositedAt, 'date'),
        chargedByName: deposit.chargedBy?.fullName || '-'
      }))
    },
    isEditingDeposit() {
      return !!this.editingDeposit
    },
    totalGrossValue() {
      return this.formatValue(this.order?.payment.totalGrossValue, 'price')
    },
    depositsSum() {
      return this.formatValue(
        this.data.codDeposits.reduce((acc, obj) => acc + obj.depositedAmount, 0),
        'price'
      )
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog'
    }),
    formatValue,
    clearForms() {
      this.isAddingDeposit = false
      this.editingDeposit = null
    },
    setEditingDeposit(deposit) {
      this.editingDeposit = { ...deposit, depositedAmount: deposit.depositedAmount / 100 }
    },
    updateDeposit() {
      if (!this.$refs.editDepositForm.validate()) return

      this.isProcessing = true
      const { id, depositedAmount } = this.editingDeposit || {}
      const { isCourseDeoposits } = this.field || {}

      const params = {
        id,
        depositedAmount: depositedAmount * 100,
        requiredAmount: this.order.payment.totalGrossValue,
        userId: this.userId
      }

      if (isCourseDeoposits) {
        params.driverId = this.data?.driver?.id || null
      }

      if (['Gotówka przy podstawieniu', 'Gotówka przy zabraniu'].includes(this.order.payment.paymentType)) {
        delete params.userId
      }

      api
        .updateCodDeposit(params)
        .then(() => {
          this.closeDialog()
        })
        .finally(() => {
          this.isProcessing = false
          this.editingDeposit = null
        })
    },
    addDeposit() {
      if (!this.$refs.addDepositForm.validate()) return

      this.isProcessing = true
      const { isCourseDeoposits } = this.field || {}

      const params = {
        depositedAmount: this.newDepositAmount * 100,
        requiredAmount: this.order.payment.totalGrossValue,
        userId: this.userId
      }

      if (isCourseDeoposits) {
        params.driverId = this.data?.driver?.id || null
        params.courseId = this.data.id
      } else {
        params.orderId = this.order.id
      }

      api
        .addCodDeposit(params)
        .then(() => {
          this.closeDialog()
        })
        .finally(() => {
          this.isProcessing = false
          this.isAddingDeposit = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 16px 16px 32px 16px;
  background-color: rgba(149, 162, 183, 0.063);
  border-radius: 8px;
}

::v-deep th {
  background: none !important;
}
::v-deep tr {
  background-color: transparent !important;
}
::v-deep thead {
  background-color: #f1f1f1 !important;
}
::v-deep .v-data-table__wrapper {
  border: 1px solid #cfcfcf !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
</style>
